import React from 'react'

const NoEvents = () => {
  return (
    <div>
      There are no events to show. Add events?
    </div>
  )
}

export default NoEvents
